var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "message-box dialog-mask",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.onClickOutSide.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-box" }, [
        _vm._m(0),
        _c("div", { staticClass: "section dialog-body" }, [
          _c("div", { staticClass: "description font-bold" }, [
            _vm._v("削除する時系列を選択してください"),
          ]),
          _c(
            "div",
            { staticClass: "timeline-list" },
            [
              _c("div", { staticClass: "timeline-type" }, [_vm._v("年表")]),
              _vm._l(_vm.chronologyTimelines, function (timeline) {
                return _c(
                  "div",
                  {
                    key: timeline.timelineId,
                    staticClass: "list-item",
                    on: {
                      click: function ($event) {
                        return _vm.onClickTimeline(timeline)
                      },
                    },
                  },
                  [
                    _c("checkbox-icon", {
                      attrs: { isChecked: _vm.isChecked(timeline) },
                    }),
                    _c("div", { staticClass: "timeline-title ellipsis" }, [
                      _vm._v(_vm._s(timeline.name || "（タイトル未設定）")),
                    ]),
                  ],
                  1
                )
              }),
              _c("div", { staticClass: "timeline-type" }, [_vm._v("プロット")]),
              _vm._l(_vm.plotTimelines, function (timeline) {
                return _c(
                  "div",
                  {
                    key: timeline.timelineId,
                    staticClass: "list-item",
                    on: {
                      click: function ($event) {
                        return _vm.onClickTimeline(timeline)
                      },
                    },
                  },
                  [
                    _c("checkbox-icon", {
                      attrs: { isChecked: _vm.isChecked(timeline) },
                    }),
                    _c("div", { staticClass: "timeline-title ellipsis" }, [
                      _vm._v(_vm._s(timeline.name || "（タイトル未設定）")),
                    ]),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ]),
        _c("div", { staticClass: "section button-area" }, [
          _c(
            "button",
            {
              staticClass: "button cancel",
              on: { click: _vm.onNegativeClick },
            },
            [_vm._v(_vm._s(_vm.negative))]
          ),
          _c(
            "button",
            {
              staticClass: "button danger",
              attrs: { disabled: !_vm.isCompleted },
              on: { click: _vm.onPositiveClick },
            },
            [_vm._v(_vm._s(_vm.positive))]
          ),
        ]),
      ]),
      _vm.isDeleting ? _c("masked-loading") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "section dialog-header" }, [
      _c("h1", [_vm._v("時系列の削除")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }