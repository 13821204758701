
import Vue, { PropType } from "vue";
import clone from "lodash/cloneDeep";
import deepEqual from "deep-equal";
import ButtonRound from "@/components/atoms/ButtonRound.vue";
import {
  Timeline,
  TimelineType,
  TableHeader,
  TableBody,
  ListTimelinePayload,
  UpdateTimelinePayload,
} from "@/lib/models/timeline";
import { Plot, PlotGroups, Plots } from "@/lib/models/plot";
import TimelineNavigationBar from "@/components/organisms/TimelineNavigationBar.vue";
import TimelineChronologyHeader from "@/components/organisms/TimelineChronologyHeader.vue";
import TimelinePlotHeader from "@/components/organisms/TimelinePlotHeader.vue";
import TimelineBody from "@/components/organisms/TimelineBody.vue";
import { InterpolateBodyItemPayload, UpdateBodyPayload, UpdateHeaderPayload } from "@/store/modules/timeline";
import { handleShortcutKeys, TriggerKey } from "@/lib/utils/keyboardShortcuts";

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: {
    ButtonRound,
    TimelineNavigationBar,
    TimelineChronologyHeader,
    TimelinePlotHeader,
    TimelineBody,
  },
  props: {
    novelId: {
      type: String as PropType<string>,
    },
    timelineType: {
      type: String as PropType<TimelineType>,
    },
    timelineId: {
      type: String as PropType<string>,
    },
  },
  data() {
    return {
      headers: [],
      bodies: [],
      preHeaders: [],
      preBodies: [],
      isEditing: false,
      isCreated: false,
      unbindShortcutKeys: null,
    };
  },
  async created() {
    const { $store, novelId } = this;
    const { dispatch } = $store;

    await Promise.all([
      dispatch("timelineModule/initialize", { novelId } as ListTimelinePayload),
      dispatch("characterModule/initialize", novelId),
      dispatch("materialModule/initialize", novelId),
      dispatch("plotModule/initialize", novelId),
      dispatch("correlationModule/initialize", novelId),
    ]);

    /** NOTE: スクロールによるブラウザバックを防ぐ */
    window.history.pushState(null, "", null);
    window.addEventListener("popstate", () => {
      window.history.pushState(null, "", null);
    });

    // 保存ボタンのショートカットキーの有効化と解除関数の取得
    this.unbindShortcutKeys = handleShortcutKeys([
      { trigger: TriggerKey.Meta, keys: ["s"], callback: this.onClickSave },
      { trigger: TriggerKey.Ctrl, keys: ["s"], callback: this.onClickSave },
    ]);

    this.isCreated = true;
  },
  mounted() {
    this.setPreParams();
  },
  beforeDestroy() {
    // コンポーネントが破棄される前に保存ボタンのショートカットキーを解除
    if (this.unbindShortcutKeys) this.unbindShortcutKeys();
  },
  destroyed() {
    this.$store.dispatch("timelineModule/destroy");
  },
  computed: {
    timelines() {
      const { $store, $router } = this;
      const timelines = $store.getters["timelineModule/timelines"] as Timeline[];

      const { novelId, timelineId } = this;
      if (!timelineId && timelines.length) {
        const { timelineType, timelineId } = timelines[0];
        $router.push({ name: "timeline", params: { novelId, timelineType, timelineId } }, () => {});
      } else if (timelines.length === 0) $router.push({ name: "timeline", params: { novelId } }, () => {});

      return timelines;
    },
    timeline() {
      const { timelineId } = this;
      return this.$store.getters["timelineModule/timeline"](timelineId) as Timeline;
    },
    chronologyTimelines() {
      const { timelines } = this;
      return timelines.filter((timeline) => timeline.timelineType === TimelineType.chronology);
    },
    plotTimelines() {
      const { timelines } = this;
      return timelines.filter((timeline) => timeline.timelineType === TimelineType.plot);
    },
    plotGroups() {
      const { $store, timelineId, isPlot } = this;

      const plots: Plot = $store.getters["plotModule/plots"];

      if (!isPlot || !plots) return [];

      if (plots.plotGroups.some((plotGroup) => plotGroup.plots.length === 0)) {
        $store.dispatch("plotModule/interpolateEmptyPlots", timelineId);
      }

      return plots.plotGroups;
    },
    allPlots() {
      const { $store, plotGroups, timeline } = this;
      const allPlots = plotGroups.flatMap((plotGroup) => plotGroup.plots);

      /** プロットアイテムに紐づくボディアイテムが存在するかチェック */
      const { timelineId, tableBodies } = timeline;
      if (tableBodies.length) {
        allPlots.forEach((plot) => {
          const { key } = plot;
          const isEnoughBodyItem = tableBodies.some((body) => body.items.some((item) => item.key === key));
          if (!isEnoughBodyItem) {
            const interpolateBodyItemPayload: InterpolateBodyItemPayload = {
              timelineId,
              key,
            };
            $store.dispatch("timelineModule/interpolateBodyItem", interpolateBodyItemPayload);
          }
        });
      }

      return allPlots;
    },
    headersRemovedTimelineType() {
      const { headers, timelineType } = this;
      return (headers as TableHeader[]).filter((header) => header.id !== timelineType);
    },
    isPlot() {
      const { timelineType } = this;
      return (timelineType as TimelineType) === TimelineType.plot;
    },
    isShowTable() {
      const { timelineType, timelineId, isCreated } = this;
      return !!timelineType && !!timelineId && isCreated;
    },
    timelineTypeHeader() {
      const { timelineType } = this;
      const timelineTypeName = timelineType === TimelineType.chronology ? "年表" : "プロット";
      return {
        id: timelineType,
        name: timelineTypeName,
      } as TableHeader;
    },
    changed() {
      const { headers, bodies, preHeaders, preBodies } = this;
      return !deepEqual(headers, preHeaders) || !deepEqual(bodies, preBodies);
    },
  },
  methods: {
    onClickAdd() {
      (this.$refs.navbar as any).onClickAdd();
    },
    onClickEdit() {
      this.isEditing = true;
    },
    onClickSave() {
      if (!this.isEditing) return;

      const { novelId, timelineId, timelineType, headersRemovedTimelineType, bodies, setPreParams } = this;
      const { name } = this.timeline; // nameの更新はナビゲーションバーで行うので、ここではデータに持っている値をそのまま使う

      if (timelineId && timelineType) {
        const updateTimelinePayload: UpdateTimelinePayload = {
          novelId,
          timelineId,
          timelineType,
          name,
          tableHeaders: headersRemovedTimelineType,
          tableBodies: bodies,
          callback: () => setPreParams(),
        };
        this.$store.dispatch("timelineModule/updateTimeline", updateTimelinePayload);
        this.isEditing = false;
      }

      this.$notify({
        title: "保存しました",
        type: "success",
        duration: 2000,
      });
    },
    async onChangeHeaders(headers) {
      this.headers = headers;

      await this.$nextTick();

      const updateHeaderPayload: UpdateHeaderPayload = {
        timelineId: this.timelineId!,
        headers: this.headersRemovedTimelineType,
      };
      this.$store.dispatch("timelineModule/updateHeader", updateHeaderPayload);
    },
    onChangeBodies(bodies) {
      this.bodies = bodies;

      const updateBodyPayload: UpdateBodyPayload = {
        timelineId: this.timelineId!,
        bodies,
      };
      this.$store.dispatch("timelineModule/updateBody", updateBodyPayload);
    },
    setPreParams() {
      this.preHeaders = this.headers;
      this.preBodies = this.bodies;
      this.setModified(false);
    },
  },
  watch: {
    timeline: {
      handler(timeline: Timeline) {
        const { allPlots, changed } = this;

        if (timeline) {
          const { isPlot, timelineTypeHeader } = this;
          const { tableHeaders, tableBodies } = timeline;

          if (!isPlot && tableHeaders) {
            /** 年表ヘッダの更新 */
            this.headers = [timelineTypeHeader, ...clone(tableHeaders)];
          } else if (isPlot && allPlots.length) {
            /** プロットヘッダの更新 */
            const headerPlots = allPlots.map(
              (plot) =>
                ({
                  id: plot.key,
                  name: plot.text,
                } as TableHeader)
            );
            this.headers = [timelineTypeHeader, ...clone(headerPlots)];
          }

          /** ボディの更新 */
          this.bodies = clone(tableBodies);
        }

        this.setModified(changed);
      },
    },
    timelineId: {
      handler() {
        this.isEditing = false;
        this.setPreParams();
      },
      immediate: true,
    },
  },
});

interface Props {
  novelId: string;
  timelineType?: TimelineType;
  timelineId?: string;
}

interface Data {
  headers: TableHeader[];
  bodies: TableBody[];
  preHeaders: TableHeader[];
  preBodies: TableBody[];
  isEditing: boolean;
  isCreated: boolean;
  unbindShortcutKeys: (() => void) | null;
}

interface Computed {
  timelines: Timeline[];
  timeline: Timeline;
  chronologyTimelines: Timeline[];
  plotTimelines: Timeline[];
  plotGroups: PlotGroups[];
  allPlots: Plots[];
  headersRemovedTimelineType: TableHeader[];
  isPlot: boolean;
  isShowTable: boolean;
  timelineTypeHeader: TableHeader;
  changed: boolean;
}

interface Methods {
  onClickAdd(): void;
  onClickEdit(): void;
  onClickSave(): void;
  onChangeHeaders(headers: TableHeader[]): void;
  onChangeBodies(bodies: TableBody[]): void;
  setPreParams(): void;
}
