var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.onClickOutSide.apply(null, arguments)
        },
        mouseover: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.onMouseOverOutSide.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "dialog-content",
          on: { mouseover: _vm.onMouseOverContent },
        },
        [
          _c("header", [_vm._v(_vm._s(_vm.correlationGroupName))]),
          _c("correlation-diagram", {
            attrs: {
              novelId: _vm.novelId,
              diagram: _vm.diagram,
              characterItems: _vm.characters,
              selectedCorrelationList: _vm.selectedCorrelationList,
              disabledEdit: "",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }