var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "message-box dialog-mask",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.onClickOutSide.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-box" }, [
        _vm._m(0),
        _c("div", { staticClass: "section dialog-body" }, [
          _c(
            "div",
            { staticClass: "input-wrapper" },
            [
              _c("h2", [_vm._v("時系列の名前")]),
              _c("input-text", {
                attrs: { maxlength: 120 },
                model: {
                  value: _vm.timelineName,
                  callback: function ($$v) {
                    _vm.timelineName = $$v
                  },
                  expression: "timelineName",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "input-wrapper" }, [
            _c("h2", [_vm._v("時系列のヘッダ形式")]),
            _c("div", { staticClass: "format-select" }, [
              _c(
                "div",
                {
                  staticClass: "radio-button",
                  class: { disabled: !_vm.canSelectChronology },
                  on: {
                    click: function ($event) {
                      _vm.canSelectChronology &&
                        _vm.onChangeTimelineType("chronology")
                    },
                  },
                },
                [
                  _c("radio-icon", {
                    attrs: {
                      value: "chronology",
                      selected: _vm.selectedTimelineType,
                      fill: _vm.checkBoxColor,
                    },
                  }),
                  _c(
                    "label",
                    { class: { disabled: !_vm.canSelectChronology } },
                    [_vm._v("年表")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "radio-button",
                  class: { disabled: !_vm.canSelectPlot },
                  on: {
                    click: function ($event) {
                      _vm.canSelectPlot && _vm.onChangeTimelineType("plot")
                    },
                  },
                },
                [
                  _c("radio-icon", {
                    attrs: {
                      value: "plot",
                      selected: _vm.selectedTimelineType,
                      fill: _vm.checkBoxColor,
                    },
                  }),
                  _c("label", { class: { disabled: !_vm.canSelectPlot } }, [
                    _vm._v("プロット"),
                  ]),
                ],
                1
              ),
            ]),
            _vm.hasLimit
              ? _c("div", { staticClass: "caution" }, [
                  _vm._v(
                    "※ 無料会員では年表形式とプロット形式をそれぞれ1つまで作成できます。"
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "section button-area" }, [
          _c(
            "button",
            {
              staticClass: "button cancel",
              on: { click: _vm.onNegativeClick },
            },
            [_vm._v(_vm._s(_vm.negative))]
          ),
          _c(
            "button",
            {
              staticClass: "button primary",
              attrs: { disabled: !_vm.isCompleted },
              on: { click: _vm.onPositiveClick },
            },
            [_vm._v(_vm._s(_vm.positive))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "section dialog-header" }, [
      _c("h1", [_vm._v("時系列の新規作成")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }