var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "message-box dialog-mask",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.onClickOutSide.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-box" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "section dialog-body" },
          [
            _c("h2", [_vm._v("時系列に追加するアイテムを選択してください。")]),
            _c("select-box", {
              staticClass: "selectbox",
              attrs: {
                items: _vm.timelineKinds,
                keyAttr: "id",
                placeholder: "選択してください",
                selected: _vm.kind,
                enableUnselected: "",
              },
              on: { select: _vm.selectKind, unselect: _vm.unselectKind },
            }),
            _vm.needSelectItem
              ? _c("select-box", {
                  staticClass: "selectbox",
                  attrs: {
                    items: _vm.timelineItems,
                    keyAttr: "id",
                    placeholder: "選択してください",
                    iconPlaceholder: "/img/placeholders/no_image_default.png",
                    selected: _vm.item,
                    enableUnselected: "",
                    disabled: !_vm.kind,
                  },
                  on: { select: _vm.selectItem, unselect: _vm.unselectItem },
                })
              : _vm._e(),
            _vm.isShowCaution
              ? _c(
                  "div",
                  { staticClass: "caution" },
                  [
                    _vm._v(
                      " ※ 任意の資料を追加する機能はプレミアム機能です。詳しくは "
                    ),
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "subscriptionAnnounce",
                            query: { from: "addItemTimeline" },
                          },
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.onClickLink.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(" こちら ")]
                    ),
                    _vm._v(" をご確認ください。 "),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "section button-area" }, [
          _c(
            "button",
            {
              staticClass: "button cancel",
              on: { click: _vm.onNegativeClick },
            },
            [_vm._v(_vm._s(_vm.negative))]
          ),
          _c(
            "button",
            {
              staticClass: "button primary",
              attrs: { disabled: !_vm.isCompleted },
              on: { click: _vm.onPositiveClick },
            },
            [_vm._v(" " + _vm._s(_vm.positive) + " ")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "section dialog-header" }, [
      _c("h1", [_vm._v("追加するアイテムの選択")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }