var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "thead",
    [
      _c(
        "draggable",
        {
          attrs: {
            tag: "tr",
            animation: 200,
            ghostClass: "ghost",
            move: _vm.onMoveHeader,
            disabled: !_vm.isEditing,
          },
          on: { start: _vm.onDragStart, end: _vm.onDragEnd },
          model: {
            value: _vm.dataHeaders,
            callback: function ($$v) {
              _vm.dataHeaders = $$v
            },
            expression: "dataHeaders",
          },
        },
        [
          _vm._l(_vm.dataHeaders, function (header, index) {
            return _c(
              "th",
              {
                key: header.id,
                class: { isEditing: _vm.isEditing },
                attrs: { scope: "col" },
              },
              [
                index === 0
                  ? _c("div", { staticClass: "timeline-type" }, [
                      _vm._v(_vm._s(header.name)),
                    ])
                  : _c(
                      "div",
                      { staticClass: "th-container" },
                      [
                        _vm.isEditing
                          ? _c("img", {
                              staticClass: "remove",
                              attrs: {
                                src: require("@/assets/img/icon/close.svg"),
                                alt: "removeHeader",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onClickRemoveHeader(header)
                                },
                              },
                            })
                          : _vm._e(),
                        _c("input-text", {
                          class: { isEditing: _vm.isEditing },
                          attrs: { maxlength: 50, readonly: !_vm.isEditing },
                          on: {
                            input: function ($event) {
                              return _vm.onChangeHeaderName($event, header.id)
                            },
                          },
                          model: {
                            value: header.name,
                            callback: function ($$v) {
                              _vm.$set(header, "name", $$v)
                            },
                            expression: "header.name",
                          },
                        }),
                      ],
                      1
                    ),
              ]
            )
          }),
          _c(
            "th",
            {
              staticClass: "add-header",
              class: { isEditing: _vm.isEditing },
              attrs: { scope: "col" },
            },
            [
              _vm.isEditing
                ? _c(
                    "button",
                    {
                      staticClass: "button",
                      on: { click: _vm.onClickAddHeader },
                    },
                    [_vm._v("＋")]
                  )
                : _vm._e(),
            ]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }