var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("thead", [
    _c(
      "tr",
      [
        _c(
          "th",
          {
            staticClass: "timeline-type",
            attrs: { rowspan: "2", scope: "col" },
          },
          [_vm._v("プロット")]
        ),
        _vm._l(_vm.plotGroups, function (plotGroup, index) {
          return _c(
            "th",
            {
              key: index,
              class: { isEditing: _vm.isEditing },
              attrs: {
                colspan: plotGroup.plots.length || 1,
                align: "left",
                valign: "top",
              },
            },
            [
              _c("div", { staticClass: "plot-group-name" }, [
                _vm._v(_vm._s(plotGroup.name)),
              ]),
              _c("div", { staticClass: "plot-group-description scrollable" }, [
                _vm._v(_vm._s(plotGroup.description)),
              ]),
            ]
          )
        }),
      ],
      2
    ),
    _c(
      "tr",
      _vm._l(_vm.allPlots, function (plot) {
        return _c(
          "th",
          {
            key: plot.key,
            class: { isEditing: _vm.isEditing },
            attrs: { align: "left", valign: "top" },
          },
          [
            _c("div", { staticClass: "scrollable" }, [
              _vm._v(_vm._s(plot.text)),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }