var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { key: _vm.timelineId, staticClass: "timeline" },
    [
      _c("timeline-navigation-bar", {
        ref: "navbar",
        staticClass: "navbar",
        attrs: {
          novelId: _vm.novelId,
          timelineType: _vm.timelineType,
          timelineId: _vm.timelineId,
          timelines: _vm.timelines,
          currentTimeline: _vm.timeline,
          isEditing: _vm.isEditing,
        },
      }),
      _vm.isShowTable
        ? _c(
            "table",
            [
              !_vm.isPlot
                ? _c("timeline-chronology-header", {
                    attrs: {
                      timelineType: _vm.timelineType,
                      timelineId: _vm.timelineId,
                      headers: _vm.headers,
                      isEditing: _vm.isEditing,
                    },
                    on: { change: _vm.onChangeHeaders },
                  })
                : _c("timeline-plot-header", {
                    attrs: {
                      plotGroups: _vm.plotGroups,
                      isEditing: _vm.isEditing,
                    },
                  }),
              _c("timeline-body", {
                attrs: {
                  novelId: _vm.novelId,
                  timelineType: _vm.timelineType,
                  timelineId: _vm.timelineId,
                  headers: _vm.headersRemovedTimelineType,
                  bodies: _vm.bodies,
                  isEditing: _vm.isEditing,
                  isPlot: _vm.isPlot,
                },
                on: { change: _vm.onChangeBodies },
              }),
              !_vm.isEditing
                ? _c("button-round", { on: { click: _vm.onClickEdit } }, [
                    _vm._v("編集する"),
                  ])
                : _c(
                    "button-round",
                    {
                      attrs: { icon: require("@/assets/img/icon/save.svg") },
                      on: { click: _vm.onClickSave },
                    },
                    [_vm._v("保存する")]
                  ),
            ],
            1
          )
        : _c("div", { staticClass: "description" }, [
            _c("div", [
              _vm._v(
                "上部のナビゲーションバーから時系列を選択もしくは新規作成"
              ),
            ]),
            _c(
              "button",
              { staticClass: "button", on: { click: _vm.onClickAdd } },
              [_vm._v("新規作成")]
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }