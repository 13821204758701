var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "draggable",
    {
      attrs: {
        tag: "tbody",
        animation: 200,
        ghostClass: "ghost",
        move: _vm.onMoveBody,
        disabled: !_vm.isEditing,
      },
      on: { start: _vm.onDragStart, end: _vm.onDragEnd },
      model: {
        value: _vm.dataBodies,
        callback: function ($$v) {
          _vm.dataBodies = $$v
        },
        expression: "dataBodies",
      },
    },
    [
      _vm._l(_vm.dataBodies, function (body) {
        return _c(
          "tr",
          {
            key: body.id,
            class: {
              isEditing: _vm.isEditing,
              "height-initial":
                _vm.inputType(body.kind) === "text" ||
                _vm.inputType(body.kind) === "number" ||
                _vm.inputType(body.kind) === "selectbox",
            },
          },
          [
            _c("td", [
              _vm.isEditing
                ? _c("img", {
                    staticClass: "remove",
                    attrs: {
                      src: require("@/assets/img/icon/close.svg"),
                      alt: "removeBody",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.onClickRemoveBody(body)
                      },
                    },
                  })
                : _vm._e(),
              _vm.isShowItemDetail(body.kind)
                ? _c(
                    "div",
                    { staticClass: "item-detail" },
                    [
                      _vm.isRectangle(body)
                        ? _c("img", {
                            staticClass: "icon-rectangle",
                            attrs: { src: _vm.icon(body) },
                          })
                        : _c("image-view-round", {
                            staticClass: "icon",
                            attrs: {
                              src: _vm.icon(body),
                              placeholder: _vm.iconPlaceholder,
                            },
                          }),
                      _c("span", { staticClass: "label ellipsis" }, [
                        _vm._v(_vm._s(_vm.label(body))),
                      ]),
                    ],
                    1
                  )
                : _c("div", [_vm._v(_vm._s(_vm.kindName(body.kind)))]),
            ]),
            _vm._l(_vm.headers, function (header) {
              return _c(
                "td",
                {
                  key: header.id + body.id,
                  style: _vm.bodyItemStyle(body, header),
                  attrs: { align: "center" },
                },
                [
                  _vm.inputType(body.kind) === "textarea"
                    ? _c("input-textarea", {
                        class: { isEditing: _vm.isEditing },
                        attrs: {
                          rows: 5,
                          maxlength: 3000,
                          readonly: !_vm.isEditing,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.onChangeBodyItem(
                              $event,
                              body.id,
                              header.id
                            )
                          },
                        },
                        model: {
                          value:
                            body.items[_vm.findIndex(body.items, header.id)]
                              .value,
                          callback: function ($$v) {
                            _vm.$set(
                              body.items[_vm.findIndex(body.items, header.id)],
                              "value",
                              $$v
                            )
                          },
                          expression:
                            "body.items[findIndex(body.items, header.id)].value",
                        },
                      })
                    : _vm.inputType(body.kind) === "text"
                    ? _c("input-text", {
                        class: { isEditing: _vm.isEditing },
                        attrs: {
                          type: "text",
                          maxlength: 10,
                          readonly: !_vm.isEditing,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.onChangeBodyItem(
                              $event,
                              body.id,
                              header.id
                            )
                          },
                        },
                        model: {
                          value:
                            body.items[_vm.findIndex(body.items, header.id)]
                              .value,
                          callback: function ($$v) {
                            _vm.$set(
                              body.items[_vm.findIndex(body.items, header.id)],
                              "value",
                              $$v
                            )
                          },
                          expression:
                            "body.items[findIndex(body.items, header.id)].value",
                        },
                      })
                    : _vm.inputType(body.kind) === "number"
                    ? _c("input-text", {
                        class: { isEditing: _vm.isEditing },
                        attrs: {
                          type: "number",
                          min: body.kind === "emotional" ? -10 : 0,
                          max: body.kind === "emotional" ? 10 : undefined,
                          readonly: !_vm.isEditing,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.onChangeBodyItem(
                              $event,
                              body.id,
                              header.id
                            )
                          },
                        },
                        model: {
                          value:
                            body.items[_vm.findIndex(body.items, header.id)]
                              .value,
                          callback: function ($$v) {
                            _vm.$set(
                              body.items[_vm.findIndex(body.items, header.id)],
                              "value",
                              $$v
                            )
                          },
                          expression:
                            "body.items[findIndex(body.items, header.id)].value",
                        },
                      })
                    : _vm.inputType(body.kind) === "selectbox"
                    ? [
                        _vm.isEditing
                          ? _c("select-box", {
                              staticClass: "selectbox",
                              class: { isEditing: _vm.isEditing },
                              attrs: {
                                items: _vm.correlationItems,
                                keyAttr: "id",
                                placeholder: "選択してください",
                                selected: _vm.selectedCorrelationItem(
                                  body.items[
                                    _vm.findIndex(body.items, header.id)
                                  ]
                                ),
                                enableUnselected: "",
                              },
                              on: {
                                select: function ($event) {
                                  return _vm.onChangeBodyItem(
                                    $event,
                                    body.id,
                                    header.id
                                  )
                                },
                                unselect: function ($event) {
                                  return _vm.onChangeBodyItem(
                                    $event,
                                    body.id,
                                    header.id
                                  )
                                },
                              },
                            })
                          : body.items[_vm.findIndex(body.items, header.id)]
                              .value
                          ? _c(
                              "div",
                              {
                                staticClass: "correlation-name",
                                on: {
                                  click: function ($event) {
                                    _vm.onClickCorrelationName(
                                      body.items[
                                        _vm.findIndex(body.items, header.id)
                                      ].value
                                    )
                                  },
                                  mouseover: function ($event) {
                                    _vm.onMouseOverCorrelationName(
                                      body.items[
                                        _vm.findIndex(body.items, header.id)
                                      ].value
                                    )
                                  },
                                  mouseleave: _vm.onMouseLeaveCorrelationName,
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/icon/image.svg"),
                                    alt: "image",
                                  },
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.correlationName(
                                        body.items[
                                          _vm.findIndex(body.items, header.id)
                                        ]
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _vm.isEditing && body.kind !== "emotional"
                    ? _c("color-picker", {
                        staticClass: "color-picker",
                        class: _vm.inputType(body.kind),
                        on: {
                          select: function ($event) {
                            return _vm.onChangeBodyItem(
                              $event,
                              body.id,
                              header.id,
                              true
                            )
                          },
                        },
                        model: {
                          value:
                            body.items[_vm.findIndex(body.items, header.id)]
                              .color,
                          callback: function ($$v) {
                            _vm.$set(
                              body.items[_vm.findIndex(body.items, header.id)],
                              "color",
                              $$v
                            )
                          },
                          expression:
                            "body.items[findIndex(body.items, header.id)].color",
                        },
                      })
                    : _vm._e(),
                ],
                2
              )
            }),
            !_vm.isPlot ? _c("td", { staticClass: "empty" }) : _vm._e(),
            !_vm.isEditing && body.kind === "emotional"
              ? _c("bar-chart", {
                  staticClass: "chart",
                  attrs: {
                    "chart-data": _vm.chartData(body),
                    options: _vm.chartOptions,
                    height: 150,
                    width: 300 * body.items.length,
                  },
                })
              : _vm._e(),
          ],
          2
        )
      }),
      _c(
        "tr",
        { staticClass: "add-item" },
        [
          _c(
            "td",
            { class: { empty: !_vm.isEditing }, attrs: { align: "center" } },
            [
              _vm.isEditing
                ? _c(
                    "button",
                    {
                      staticClass: "button",
                      attrs: { disabled: _vm.isReachedLimit },
                      on: { click: _vm.onClickAddItem },
                    },
                    [_vm._v("＋")]
                  )
                : _vm._e(),
              _vm.isEditing && _vm.isReachedLimit
                ? _c(
                    "div",
                    { staticClass: "caution" },
                    [
                      _c("div", { staticClass: "message" }, [
                        _vm._v(
                          "プレミアム会員になると6行以降も追加が可能です。"
                        ),
                      ]),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "subscriptionAnnounce",
                              query: { from: "addItemTimeline" },
                            },
                          },
                        },
                        [_vm._v(" プレミアム機能を確認する ＞ ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _vm._l(_vm.headers, function (header) {
            return _c("td", { key: header.id, staticClass: "empty" })
          }),
          !_vm.isPlot ? _c("td", { staticClass: "empty" }) : _vm._e(),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }